import { useEffect, useState } from "react";
import Image from "next/image";
const FlagComponent = ({
  code,
  width = 16,
  height = 12
}) => {
  const flagSrc = `https://flagcdn.com/${width}x${height}/${code}.png`;
  return <Image src={flagSrc} alt={`${code} flag`} width={width} height={height} data-sentry-element="Image" data-sentry-component="FlagComponent" data-sentry-source-file="index.tsx" />;
};
export default FlagComponent;