"use client";

import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Link from "next/link";
import { ROUTES } from "@src/constants";
import "./style.css";
const FAButton = () => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  useEffect(() => {
    // detect event click outside
    document.addEventListener("click", e => {
      if (!e.target.closest(".fab-wrapper")) {
        setOpen(false);
      }
    });
  }, []);
  return <Box className="fab-wrapper" data-sentry-element="Box" data-sentry-component="FAButton" data-sentry-source-file="index.tsx">
      <input id="fabCheckbox" type="checkbox" className="fab-checkbox" checked={open} onClick={handleToggle} onChange={() => {}} />
      <label className="fab" htmlFor="fabCheckbox">
        <span className="fab-dots fab-dots-1"></span>
        <span className="fab-dots fab-dots-2"></span>
        <span className="fab-dots fab-dots-3"></span>
      </label>
      <div className="fab-wheel">
        <Link className="fab-action fab-action-1" href="https://zalo.me/0775665912" target="_blank" data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <div className="logo zalo" />
        </Link>
        <Link className="fab-action fab-action-2" href="http://m.me/theciusaigon" target="_blank" data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <div className="logo messenger" />
        </Link>
        <Link className="fab-action fab-action-3" href="tel:0775665912" data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <div className="logo phone" />
        </Link>
        <Link className="fab-action fab-action-4" href={ROUTES.BRANCH} data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <div className="logo location" />
        </Link>
      </div>
    </Box>;
};
export default FAButton;