"use client";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { ConfirmButton, Icons, Logo, NextLink } from "@src/components";
import { ROUTES } from "@src/constants/routes";
import { usePathname, useRouter } from "next/navigation";
import * as React from "react";
import AccountPopover from "./account-popover";
import BadgeIcon from "./badge-icon";
import MenuList from "./menu";
import DrawerSearch from "./search/search-drawer";
import VoucherNotification from "./voucher-notification";
import fetchClient from "@src/api/lib/fetch-client";
import { NotificationType } from "@src/models/enum/common";
import LanguageSelector from "./language-selector";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTrendingKeywords } from "@src/store/selectors/config";
import { useLayoutContext } from "./provider";
interface Props {
  onNavOpen: () => void;
}
const TopNav = (props: Props) => {
  const {
    onNavOpen
  } = props;
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const pathname = usePathname();
  const {
    config,
    menuConfigList,
    user
  } = useLayoutContext();
  const trendingWords = useSelector(selectTrendingKeywords);
  const [openSearchDrawer, setOpenSearchDrawer] = React.useState(false);
  const [openAccountPopover, setOpenAccountPopover] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [voucherNotification, setVoucherNotification] = React.useState(null);
  const accountAnchorEl = React.useRef(null);
  const ConfigMobilePageName = React.useMemo(() => [{
    path: ROUTES.RECRUITMENT,
    name: t("nav.recruitment")
  }, {
    path: ROUTES.ABOUT_US,
    name: t("nav.about_us")
  }, {
    path: ROUTES.MEMBERSHIP,
    name: t("nav.membership")
  }, {
    path: ROUTES.BRANCH,
    name: t("nav.branch")
  }, {
    path: ROUTES.FAQ,
    name: t("nav.faq")
  }, {
    path: ROUTES.COLLECTION,
    name: t("nav.collections")
  }, {
    path: ROUTES.EVENT,
    name: t("nav.event")
  }, {
    path: ROUTES.PRODUCT,
    name: t("nav.products")
  }, {
    path: ROUTES.PROFILE_ORDERS,
    name: t("nav.profile.order_management"),
    beforePath: "",
    isArrow: true
  }, {
    path: ROUTES.PROFILE_NOTIFICATION,
    name: t("nav.profile.notifications"),
    beforePath: "",
    isArrow: true
  }, {
    path: ROUTES.PROFILE_FAVORITE_PRODUCTS,
    name: t("nav.profile.favorite_products"),
    beforePath: "",
    isArrow: true
  }, {
    path: ROUTES.PROFILE_INFO,
    name: t("nav.profile.personal_info"),
    beforePath: "",
    isArrow: true
  }, {
    path: ROUTES.PROFILE_ADDRESS,
    name: t("nav.profile.address_book"),
    beforePath: "",
    isArrow: true
  }, {
    path: ROUTES.PROFILE_ADDRESS_ADD,
    name: t("nav.profile.new_address"),
    beforePath: "",
    isArrow: true
  }, {
    path: ROUTES.PROFILE_ADDRESS_EDIT,
    name: t("nav.profile.update_address"),
    isArrow: true
  }, {
    path: ROUTES.PROFILE_VOUCHER,
    name: t("nav.profile.voucher"),
    isArrow: true
  }, {
    path: ROUTES.PROFILE,
    name: t("nav.profile.profile_page"),
    beforePath: "",
    isArrow: true
  }, {
    path: ROUTES.CART_PAYMENT,
    name: t("nav.cart.checkout"),
    beforePath: "",
    isArrow: true
  }, {
    path: ROUTES.CART,
    name: t("nav.cart.shopping_cart"),
    beforePath: "",
    isArrow: true
  }, {
    path: ROUTES.BLOG,
    name: t("nav.blog"),
    beforePath: "",
    isArrow: true
  }], [t]);
  const currentRoute = React.useMemo(() => ConfigMobilePageName.find(item => pathname.startsWith(item.path)), [pathname]);
  const isPathnameProfile = React.useMemo(() => pathname.startsWith(ROUTES.PROFILE), [pathname]);
  const handleRedirect = path => () => {
    router.push(path);
  };
  const handleToggleSearchDrawer = (status: boolean) => () => {
    setOpenSearchDrawer(status ?? !openSearchDrawer);
  };
  const handleBackProfileMobile = () => {
    if (pathname === ROUTES.PROFILE) {
      router.push(ROUTES.HOME_PAGE);
    } else {
      router.push(ROUTES.PROFILE);
    }
  };
  const getNotification = async () => {
    const response = await fetchClient({
      method: "GET",
      url: "/auth/notifications",
      config: {
        deviceAuthenticated: true
      }
    });
    if (response?.data) {
      const data = response.data;
      setNotifications(data);
      setInterval(() => {
        setVisibleNotification(data);
      }, 12000);
    }
  };
  React.useEffect(() => {
    getNotification();
  }, []);
  const markNotificationAsRead = id => {
    fetchClient({
      method: "POST",
      url: `/auth/notifications/${id}/read`,
      config: {
        deviceAuthenticated: true
      }
    });
  };
  const setVisibleNotification = data => {
    const notification = data.find(n => !n.read_at && n.data.type === NotificationType.VOUCHER_NOT_USED);
    if (notification) {
      notification["read_at"] = 1;
      markNotificationAsRead(notification.id);
    }
    setVoucherNotification(notification);
    setTimeout(() => {
      setVoucherNotification(null);
    }, 11000);
  };
  return <Box component="header" id="header" data-sentry-element="Box" data-sentry-component="TopNav" data-sentry-source-file="top-nav.tsx">
      <Box sx={{
      backdropFilter: "blur(6px)",
      backgroundColor: theme => alpha(theme.palette.background.default, 1),
      top: 0,
      zIndex: theme => theme.zIndex.appBar,
      margin: "auto",
      padding: {
        xs: "0 16px",
        md: "0 24px"
      },
      boxShadow: "4px 20px 20px -20px rgba(16, 24, 40, 0.06)"
    }} data-sentry-element="Box" data-sentry-source-file="top-nav.tsx">
        <Stack justifyContent="space-between" alignItems="center" sx={{
        height: {
          xs: 56,
          lg: 76
        },
        maxWidth: "1440px",
        margin: "auto"
      }} data-sentry-element="Stack" data-sentry-source-file="top-nav.tsx">
          <Stack display={{
          xs: "flex",
          xl: "none"
        }} gap="8px" sx={{
          "& .MuiIconButton-root": {
            width: 32,
            height: 32,
            padding: "4px"
          },
          "& svg": {
            color: "#434343"
          }
        }} data-sentry-element="Stack" data-sentry-source-file="top-nav.tsx">
            {isPathnameProfile ? <Icons name="arrow-left" type="button" onClick={handleBackProfileMobile} /> : <>
                <Icons name="hamburger" type="button" onClick={onNavOpen} />
                <Icons name="search" type="button" onClick={() => setOpenSearchDrawer(true)} />
              </>}
          </Stack>

          {/* mobile site */}
          <Box display={{
          xs: "block",
          xl: "none"
        }} sx={{
          width: "100%",
          textAlign: "center"
        }} onClick={handleRedirect(currentRoute?.path || "/")} data-sentry-element="Box" data-sentry-source-file="top-nav.tsx">
            {currentRoute?.name ? <Typography variant="h3" sx={{
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "25px",
            paddingLeft: 0
          }}>
                {currentRoute?.name}
              </Typography> : <Box sx={{
            height: 16,
            "& svg": {
              width: "100%",
              height: "100%"
            }
          }} role="button" onClick={handleRedirect(ROUTES.HOME_PAGE)}>
                <Logo />
              </Box>}
          </Box>

          {/* desktop site */}
          <Box sx={{
          cursor: "pointer",
          display: {
            xs: "none",
            xl: "flex"
          },
          alignItems: "center"
        }} role="button"
        // onClick={handleRedirect("/")}
        data-sentry-element="Box" data-sentry-source-file="top-nav.tsx">
            <NextLink href={"/"} data-sentry-element="NextLink" data-sentry-source-file="top-nav.tsx">
              <Logo data-sentry-element="Logo" data-sentry-source-file="top-nav.tsx" />
            </NextLink>
          </Box>

          <Box width="100%" height="100%" sx={{
          display: {
            xs: "none",
            xl: "flex"
          },
          zIndex: 999
        }} data-sentry-element="Box" data-sentry-source-file="top-nav.tsx">
            <MenuList menuConfigList={menuConfigList} currentPath={pathname} onRedirect={handleRedirect} data-sentry-element="MenuList" data-sentry-source-file="top-nav.tsx" />
          </Box>

          <Stack alignItems="center" spacing={1} data-sentry-element="Stack" data-sentry-source-file="top-nav.tsx">
            {/* Mobile */}
            <Stack display={{
            xs: "flex",
            xl: "none"
          }} gap="8px" sx={{
            "& .MuiIconButton-root": {
              width: 32,
              height: 32,
              padding: "4px"
            },
            "& svg": {
              color: "#434343"
            }
          }} data-sentry-element="Stack" data-sentry-source-file="top-nav.tsx">
              <LanguageSelector data-sentry-element="LanguageSelector" data-sentry-source-file="top-nav.tsx" />
              {isPathnameProfile ? <>
                  <BadgeIcon iconName="ecommerce" badgeNumber={config?.total_cart_items || 0} onClick={handleRedirect(ROUTES.CART)} />
                  <Icons name="home" type="button" onClick={handleRedirect(ROUTES.HOME_PAGE)} />
                </> : <>
                  <BadgeIcon iconName="ecommerce" badgeNumber={config?.total_cart_items || 0} onClick={handleRedirect(ROUTES.CART)} />
                  <span style={{
                position: "relative"
              }}>
                    <BadgeIcon iconName="notification" badgeNumber={0} onClick={handleRedirect(ROUTES.PROFILE_NOTIFICATION)} />
                    {voucherNotification && <VoucherNotification data={voucherNotification.data} />}
                  </span>
                  <Icons name="account" type="button" onClick={handleRedirect(ROUTES.SIGN_IN)} />
                </>}
            </Stack>

            {/* Desktop */}
            <Stack display={{
            xs: "none",
            xl: "flex"
          }} gap="16px" alignItems="center" sx={{
            "& .MuiIconButton-root": {
              width: 24,
              height: 24,
              padding: "0px"
            },
            "& svg": {
              color: "#434343"
            }
          }} data-sentry-element="Stack" data-sentry-source-file="top-nav.tsx">
              <LanguageSelector data-sentry-element="LanguageSelector" data-sentry-source-file="top-nav.tsx" />
              <Icons name="search" type="button" sx={{
              "& svg": {
                color: "#434343"
              }
            }} onClick={handleToggleSearchDrawer(true)} data-sentry-element="Icons" data-sentry-source-file="top-nav.tsx" />
              <NextLink href={ROUTES.PROFILE_FAVORITE_PRODUCTS} data-sentry-element="NextLink" data-sentry-source-file="top-nav.tsx">
                <BadgeIcon iconName="heart" badgeNumber={user?.product_ids?.length || 0} onClick={() => {}} data-sentry-element="BadgeIcon" data-sentry-source-file="top-nav.tsx" />
              </NextLink>
              <span style={{
              position: "relative"
            }}>
                <BadgeIcon iconName="notification" badgeNumber={0} onClick={handleRedirect(ROUTES.PROFILE_NOTIFICATION)} data-sentry-element="BadgeIcon" data-sentry-source-file="top-nav.tsx" />
                {voucherNotification && <VoucherNotification data={voucherNotification.data} />}
              </span>
              <BadgeIcon iconName="ecommerce" badgeNumber={config?.total_cart_items || 0} onClick={handleRedirect(ROUTES.CART)} data-sentry-element="BadgeIcon" data-sentry-source-file="top-nav.tsx" />
              {user ? <div ref={accountAnchorEl}>
                  <ConfirmButton sx={{
                padding: "12px !important",
                maxWidth: 150,
                height: "36px !important",
                minHeight: "36px !important",
                "& span.label": {
                  maxWidth: 100,
                  overflow: "hidden",
                  display: "block",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }
              }} label={<span className="label">{user?.full_name}</span>} startIcon={<Icons sx={{
                "& svg": {
                  color: "white !important"
                }
              }} name="user" />} onClick={() => setOpenAccountPopover(true)} />
                </div> : <ConfirmButton sx={{
              padding: "12px !important",
              height: "36px !important",
              minHeight: "36px !important"
            }} label={t("login")} startIcon={<Icons sx={{
              "& svg": {
                color: "white !important"
              }
            }} name="user" />} onClick={handleRedirect(ROUTES.SIGN_IN)} />}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <DrawerSearch open={openSearchDrawer} toggleDrawer={handleToggleSearchDrawer} suggestKeyword={trendingWords} data-sentry-element="DrawerSearch" data-sentry-source-file="top-nav.tsx" />
      <AccountPopover anchorEl={accountAnchorEl.current} open={!!openAccountPopover} onClose={() => setOpenAccountPopover(false)} data-sentry-element="AccountPopover" data-sentry-source-file="top-nav.tsx" />
    </Box>;
};
export default TopNav;