"use client";

import { useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { Menu, Radio, MenuItem, Typography } from "@mui/material";
import BadgeIcon from "./badge-icon";
import { useTranslation } from "react-i18next";
import i18nConfig from "@i18nConfig";
import { setCookie } from "@src/api/lib/cookieUtils";
export default function LanguageSelector() {
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    i18n
  } = useTranslation();
  const currentLocale = i18n.language;
  const currentPathname = usePathname();
  const changeLanguage = (newLocale: string) => {
    setCookie("NEXT_LOCALE", newLocale, 30);
    if (currentLocale === i18nConfig.defaultLocale && !i18nConfig.prefixDefault && currentPathname) {
      router.push("/" + newLocale + currentPathname);
    } else if (currentPathname) {
      router.push(currentPathname.replace(`/${currentLocale}`, `/${newLocale}`));
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuStyles = {
    "& .MuiPaper-root": {
      borderRadius: "10px"
    }
  };
  const menuItemStyles = {
    minHeight: "auto",
    padding: "0 16px 0 16px" // Default padding
  };
  const LanguageMenuItem = ({
    locale,
    label
  }: {
    locale: string;
    label: string;
  }) => <MenuItem sx={menuItemStyles} onClick={() => {
    changeLanguage(locale);
    handleClose();
  }} data-sentry-element="MenuItem" data-sentry-component="LanguageMenuItem" data-sentry-source-file="language-selector.tsx">
      <Radio sx={{
      color: "#000000"
    }} checked={currentLocale === locale} color="error" size="small" data-sentry-element="Radio" data-sentry-source-file="language-selector.tsx" />
      <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="language-selector.tsx">{label}</Typography>
    </MenuItem>;
  return <>
      <BadgeIcon iconName="languageIcon" badgeNumber={0} onClick={handleClick} data-sentry-element="BadgeIcon" data-sentry-source-file="language-selector.tsx" />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }} sx={menuStyles} data-sentry-element="Menu" data-sentry-source-file="language-selector.tsx">
        <LanguageMenuItem locale="vi" label="VI" data-sentry-element="LanguageMenuItem" data-sentry-source-file="language-selector.tsx" />
        <LanguageMenuItem locale="en" label="EN" data-sentry-element="LanguageMenuItem" data-sentry-source-file="language-selector.tsx" />
      </Menu>
    </>;
}