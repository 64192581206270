"use client";

import { ReactNode, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getCurrencyCodeFromCookie } from "@src/api/lib/currencyUtils";
import { setCookie } from "@src/api/lib/cookieUtils";
import FlagComponent from "../FlagComponent";
interface CurrencyType {
  code: string;
  name: ReactNode;
}
const currencies: CurrencyType[] = [{
  code: "USD",
  name: <><FlagComponent code="us" />&nbsp; USD</>
}, {
  code: "EUR",
  name: <><FlagComponent code="eu" />&nbsp; EUR</>
}, {
  code: "GBP",
  name: <><FlagComponent code="gb" />&nbsp; GBP</>
}, {
  code: "JPY",
  name: <><FlagComponent code="jp" />&nbsp; JPY</>
}, {
  code: "CNY",
  name: <><FlagComponent code="cn" />&nbsp; CNY</>
}, {
  code: "AUD",
  name: <><FlagComponent code="au" />&nbsp; AUD</>
}, {
  code: "CAD",
  name: <><FlagComponent code="ca" />&nbsp; CAD</>
}, {
  code: "CHF",
  name: <><FlagComponent code="ch" />&nbsp; CHF</>
}, {
  code: "HKD",
  name: <><FlagComponent code="hk" />&nbsp; HKD</>
}, {
  code: "SGD",
  name: <><FlagComponent code="sg" />&nbsp; SGD</>
}, {
  code: "INR",
  name: <><FlagComponent code="in" />&nbsp; INR</>
}, {
  code: "KRW",
  name: <><FlagComponent code="kr" />&nbsp; KRW</>
}, {
  code: "BRL",
  name: <><FlagComponent code="br" />&nbsp; BRL</>
}, {
  code: "MXN",
  name: <><FlagComponent code="mx" />&nbsp; MXN</>
}, {
  code: "ZAR",
  name: <><FlagComponent code="za" />&nbsp; ZAR</>
}, {
  code: "VND",
  name: <><FlagComponent code="vn" />&nbsp; VND</>
}];
const CurrencySelector = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCurrency, setSelectedCurrency] = useState(currencies.find(c => c.code === getCurrencyCodeFromCookie()));
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (currency: CurrencyType | null = null) => {
    setAnchorEl(null);
    if (currency) {
      setSelectedCurrency(currency);
      setCookie("NEXT_CURRENCY_CODE", currency.code, 7);
      window.location.reload();
    }
    ;
  };
  return <div style={{
    position: "fixed",
    bottom: "17.5rem",
    left: "10px",
    zIndex: 999
  }} data-sentry-component="CurrencySelector" data-sentry-source-file="index.tsx">
      <Button variant="outlined" onClick={handleClick} endIcon={<KeyboardArrowDownIcon />} data-sentry-element="Button" data-sentry-source-file="index.tsx">
        {selectedCurrency?.name}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose()} data-sentry-element="Menu" data-sentry-source-file="index.tsx">
        {currencies.map(currency => <MenuItem key={currency.code} onClick={() => handleClose(currency)}>
            {currency.name}
          </MenuItem>)}
      </Menu>
    </div>;
};
export default CurrencySelector;